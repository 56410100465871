import React from "react";
import { HydraAdmin } from "@api-platform/admin";

// Replace with your own API entrypoint
// For instance if https://example.com/api/books is the path to the collection of book resources, then the entrypoint is https://example.com/api

export default () => (
 // <HydraAdmin entrypoint="http://localhost/api" />
  <HydraAdmin entrypoint="https://api.structurea.be/api" />
);

/* let ret = () => {
  return <HydraAdmin entrypoint="http://127.0.0.1:8000/api/posts" />;
  //<div>Bonjour</div>
};
export default ret; */



/* 
import React,{useState,useEffect} from "react";
import { HydraAdmin } from "@api-platform/admin";
import axios from "axios";


// Replace with your own API entrypoint
// For instance if https://example.com/api/books is the path to the collection of book resources, then the entrypoint is https://example.com/api

let loadData = () => {
  axios.get("http://127.0.0.1:8000/api/posts").then(response => console.log(response))
}

let App = () => {
  useEffect(() => loadData);
  return <HydraAdmin entrypoint="http://127.0.0.1:8000/api" />;
  //<div>Bonjour</div>
};
export default App;
 */

 
/* import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

export default App; */
